
import { defineComponent, ref, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import * as Yup from "yup";
import YupPassword from "yup-password";
YupPassword(Yup);
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import "yup-phone-lite";

export default defineComponent({
  name: "sign-up!",
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const { t, te } = useI18n();

    const i18n = useI18n();
    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";

    const countries = {
      en: {
        flag: "/media/flags/united-states.svg",
        name: "English"
      },
      es: {
        flag: "/media/flags/spain.svg",
        name: "Spanish"
      },
      de: {
        flag: "/media/flags/germany.svg",
        name: "German"
      },
      ja: {
        flag: "/media/flags/japan.svg",
        name: "Japanese"
      },
      fr: {
        flag: "/media/flags/france.svg",
        name: "French"
      }
    };

    const setLang = lang => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = lang => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    const translate = text => {
      return te(text) ? t(text) : text;
    };

    const submitButton = ref<HTMLElement | null>(null);

    const registration = Yup.object().shape({
      name: Yup.string().required(
        translate("name") + " " + translate("isRequired")
      ),
      surname: Yup.string().required(
        translate("lastName") + " " + translate("isRequired")
      ),
      toc: Yup.bool().required(
        translate("Accept") + " " + translate("isRequired")
      ),
      phone: Yup.string()
        .phone("AR", translate("phone") + " " + translate("validPhone"))
        .required(translate("phone") + " " + translate("isRequired")),
      email: Yup.string()
        .min(4)
        .required(translate("email") + " " + translate("isRequired"))
        .email(translate("email") + " " + translate("validEmail")),
      password: Yup.string()
        .minUppercase(
          1,
          translate("password") + " " + translate("minUppercase")
        )
        .minNumbers(1, translate("password") + " " + translate("minNumbers"))
        .minSymbols(1, translate("password") + " " + translate("minSymbols"))
        .min(8, translate("password") + " " + translate("min"))
        .required(translate("password") + " " + translate("isRequired")),
      cpassword: Yup.string()
        .required(translate("passwordConfirm") + " " + translate("isRequired"))
        .oneOf([Yup.ref("password"), null], translate("PasswordsMustMatchYup"))
    });

    const onSubmitRegister = values => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // Dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.REGISTER, values)
          .then(() => {
            // Swal.fire({
            // text: "All is cool! Now you submit this form",
            // icon: "success",
            // buttonsStyling: false,
            // confirmButtonText: "Ok, got it!",
            // customClass: {
            // confirmButton: "btn fw-bold btn-light-primary"
            // }
            // }).then(function() {
            // Go to page after successfully login
            // router.push({ name: "dashboard" });
            // });
            router.push({ name: "dashboard" });
          })
          .catch(() => {
            Swal.fire({
              text: translate("alreadyInUse"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: translate("tryAgain"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger"
              }
            });
          });

        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
      translate
    };
  }
});
